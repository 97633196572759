<template>
  <b-modal
    id="ModalConfigIntegration"
    modal-class="dialog-800"
    :title="`${$t('Configuración de integracion')} ${titleName}`"
    no-close-on-backdrop
    @close="() => close(false)"
    hide-footer
  >
  <!-- CustomForm -->
    <form-render :form.sync="formCustomIntegration" :fields="fieldsCustomIntegration" containerButtonsClass="col-sm-12" :key="keyFormRenderCustomIntegration"       ref="formRenderCustomIntegration">
      <template #link="scope">
        <a class="lnk lnk-primary" :href="scope.fieldItem.url" target="_blank">{{ scope.fieldItem.label }}</a>
      </template>
    </form-render>
    <!-- Integration Form -->
    <form-render :form.sync="formIntegration"
      :fields="fieldsIntegration"
      containerButtonsClass="col-sm-12"
      :key="keyFormRenderIntegration"
      ref="formRenderIntegration"
    >
    <!-- Configuration Table -->
      <template #tableConf>
        <label class="label-form col-12 pl-0">Configuración marketplace</label>
        <b-button size="sm" @click="() => showConfigInput(!configMarketplace.viewInput)" variant="secondary" class="mb-1">
          <feather-icon :icon="configMarketplace.viewInput ? 'XIcon': 'PlusIcon'"/>
        </b-button>
        <div v-if="!configMarketplace.viewInput">
          <div v-if="configMarketplace.dataList.length === 0">
            <p class="text-center">No existen valores configurados</p>
          </div>
          <div v-else>
            <table-render :key="keyTableConfgIntegRender" id="companyConfgInteg" :rows.sync="rowsTableConfgInteg" :schema="schemaTableConfgInteg" class="px-1"  stickyHeader="true">
              <template #multibulto="scope">
                {{(scope.rowdata.extra_rules && scope.rowdata.extra_rules.multipackage) ? 'Multibulto' : 'Monobulto'}}
              </template>
              <template #extraRules="scope">
                {{scope.rowdata.extra_rules || 'vacío'}}
              </template>
            </table-render>
          </div>        
        </div>
        <div v-else>
          <b-form-textarea
            id="textareaConfigMkc" v-model="textareaConfigMkc" rows="2"
            placeholder="Inserte aquí la(s) líneas de código que desea añadir"/>
        </div>   
      </template>
    </form-render>
    <div>
      <b-button class="float-right mt-1 mb-1" variant="warning" @click="addConfigIntegration" :disabled="loading.configIntg">
        <span v-if="!loading.configIntg" class="mb-1">Guardar</span>
        <span class="mt-1 p-2" v-if="loading.configIntg"><b-spinner small/> </span>
      </b-button>
    </div>
  </b-modal>
</template>
 
<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'

export default {
  name: 'ModalConfigIntegration',
  props: ['integration', 'refresh', 'warehouses'],
  data() {
    return {
      fieldsCustomIntegration: [],
      fieldsIntegration: [],
      rowsTableConfgInteg: [],
      schemaTableConfgInteg: [],
      formCustomIntegration: {},
      formIntegration: {},
      keyFormRenderCustomIntegration: 0,
      keyFormRenderIntegration: 1,
      keyTableConfgIntegRender: 2,
      loading: {
        configIntg: false
      },
      fieldsTypes: Object.freeze({
        text: 'FieldInput',
        number: 'FieldInput',
        select: 'FieldSelect',
        checkbox: 'FieldCheckbox',
        textarea: 'FieldTextarea'
      }),
      bilateralVal: Object.freeze([
        { id: 5, text: 'Listo para despacho' },
        { id: 6, text: 'En planta origen' }
      ]),
      configMarketplace: {
        viewInput: false,
        dataList: []
      },
      textareaConfigMkc: null,
      titleName: '',
      deliveryStatuses: [],
      newSellersAdd: null,
      baseService: new BaseServices(this)
    }
  },
  computed: {
    ...mapGetters({
      listIntegrationsCompany: 'getListIntegrationsCompany',
      statusShipped: 'getStatusShipped'
    })
  },
  watch: {
    integration() {
      this.titleName = ` - ${this.integration.name}`
      //Se parsea el json para poder "crear" el dato para llenar la tabla (schemaTableConfgInteg)
      //Validamos que exista ya que hay algunos que no tiene este valor, tampoco viene la key
      if (this.integration.sellers && Object.keys(this.integration.sellers).length > 0) {
        const sellers = Object.keys(this.integration.sellers).map(key => ({
          seller: key,
          ...this.integration.sellers[key]
        }))
        this.configMarketplace.dataList = sellers
      }
      //Generamos los inputs en cada cambio del prop
      this.generatInputs()
    },
    statusShipped() {
      this.deliveryStatuses = this.statusShipped
      const indexShippedStatus = this.getIndex('status_shipped', this.fieldsIntegration, 'name')
      this.fieldsIntegration[indexShippedStatus].options = this.deliveryStatuses
      if (this.integration.status_shipped) {
        this.formIntegration.status_shipped = this.deliveryStatuses[this.getIndex(this.integration.status_shipped, this.deliveryStatuses, 'id')]
      }
    }
  },
  mounted() {
    if (this.integration) this.generatInputs()
  },
  methods: {
    validateCustomization() {
      //Validacion checkbox sin value
      this.integration.customization.forEach((item, index) => {
        if (item.type === 'checkbox') {
          this.integration.customization[index].value = !!item?.value
        }
      })
    },
    generatInputs () {
      this.validateCustomization()
      const { customization, id } = this.integration
      this.cleanFormAndFields()
      // Campos Custom Form - dependiendo el type hace una cosa u otra
      Object.keys(customization).forEach(key => {
        const additionalInf = {}
        if (this.validitionToShow(customization) && !['checkbox', 'select', 'link', 'textarea'].includes(customization[key].type)) {
          this.fieldsCustomIntegration.push(
            { 
              fieldType: this.fieldsTypes[customization[key].type || 'text'],
              useLabel: true,
              name: customization[key].field,
              label: customization[key].label,
              textHelp: customization[key].example || null,
              containerClass: 'col-12 col-md-6 container-info',
              disabled: customization[key]?.disabled
            }
          )
        } else if (customization[key].type === 'link') {
          this.fieldsCustomIntegration.push({ name: 'link', useSlotWithData: true, containerClass: 'col-12 col-md-6 container-info d-flex justify-content-center', data: customization[key] })
        } else  {
          const { type } = customization[key]
          if (['checkbox', 'select'].includes(type)) additionalInf.options = type === 'select' ? customization[key].options.map(val => ({ id: val, text: val})) : [{id: !!customization[key]?.value, text: ''}]
          this.fieldsCustomIntegration.push(
            {
              fieldType: this.fieldsTypes[customization[key].type],
              useLabel: true,
              name: customization[key].field,
              label: customization[key].label,
              containerClass: 'col-12 col-md-6 container-info',
              ...additionalInf
            }
          )
        }
        if (['select'].includes(customization[key].type)) {
          this.formCustomIntegration[customization[key].field] = additionalInf.options[this.getIndex(customization[key].value, additionalInf.options, 'id')]
        } else if (['checkbox'].includes(customization[key].type)) {
          this.formCustomIntegration[customization[key].field] = !!additionalInf?.options[0]?.id
        } else {
          this.formCustomIntegration[customization[key].field] = typeof customization[key].value === 'object' ? JSON.stringify(customization[key].value) : customization[key].value || ''
        }
      })
      // Campos Condicionales al ID - Form integrations
      if (id === 1) {   
        this.fieldsIntegration.push({ fieldType: 'FieldSelect', useLabel: true, name: 'bilateral', label: 'Bilateral', containerClass: 'col-12 col-md-6 container-info', options: this.bilateralVal })
        if (this.integration.bilateral) {
          const value = this.bilateralVal[this.getIndex(Number(this.integration.bilateral), this.bilateralVal, 'id')]
          this.formIntegration.bilateral = value
        }
      }
      if (![2, 5].includes(id)) {   
        this.fieldsIntegration.push({ fieldType: 'FieldSelect', useLabel: true, name: 'warehouse_id', label: 'Lugar de retiro / Bodega', containerClass: 'col-12 col-md-6 container-info', options: this.warehouses.map(val => ({id: val.id, text: val.name})), searchOnType: { fx: this.getWarehouse, nChars: 3, debounce: 600, persistSearch: true} })
        if (this.integration.warehouse_id) {
          const value = this.warehouses[this.getIndex(Number(this.integration.warehouse_id), this.warehouses, 'id')]
          this.formIntegration.warehouse_id = value
        }
      }
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
        this.fieldsIntegration.push(
          { fieldType: 'FieldTextarea', useLabel: true, name: 'rule_tags', label: 'Tags', rows: 1, containerClass: 'col-12 col-md-6 container-info' },
          { fieldType: 'FieldTextarea', useLabel: true, name: 'custom_mapping', label: 'Mapping', rows: 1, containerClass: 'col-12 col-md-6 container-info' }
        )
        if (this.integration.rule_tags) this.formIntegration.rule_tags = typeof this.integration.rule_tags === 'object' ? JSON.stringify(this.integration.rule_tags) : this.integration.rule_tags
        if (this.integration.custom_mapping) this.formIntegration.custom_mapping = typeof this.integration.custom_mapping === 'object' ? JSON.stringify(this.integration.custom_mapping) : this.integration.custom_mapping
        // Tabla MKP Config
        if (this.listIntegrationsCompany.company_data?.marketplace) {
          this.fieldsIntegration.push({ name: 'tableConf', useSlot: true, containerClass: 'col-12 container-info' })
          if (this.integration.sellers && Object.keys(this.integration.sellers).length > 0) {
            this.schemaTableConfgInteg = [
              { label: 'Seller Envíame', key: 'seller'},
              { label: 'ID', key: 'company_id' },
              { label: 'Código Bodega', key: 'warehouse_code' },
              { label: 'Multibulto /Monobulto', key: 'multibulto', useSlot: true },
              { label: 'Extra rule', key: 'extraRules', useSlot: true }
            ]
            this.rowsTableConfgInteg = this.configMarketplace.dataList
          } else {
            this.configMarketplace = {
              viewInput: false,
              dataList: []
            }
          }
        }
      }
      if (id === 4) {
        this.fieldsIntegration.push({ fieldType: 'FieldTextarea', useLabel: true, name: 'statuses', label: 'Estados para creación de envío', containerClass: 'col-12 container-info' })
        if (this.integration.statuses) this.formIntegration.statuses = typeof this.integration.statuses === 'object' ? JSON.stringify(this.integration.statuses) : this.integration.statuses
      }
      if (id === 3) {
        //De donde la ref de los decimales
        this.fieldsIntegration.push({ fieldType: 'FieldInput', useLabel: true, name: 'decimal', label: 'Decimales a cortar', containerClass: 'col-12 col-md-6 container-info' })
        this.formIntegration.decimal = this.integration.decimal ? this.integration.decimal : 0
      }
      if (id === 7) {
        const hasValue = Object.keys(this.statusShipped).length
        //Si no hay atos en el store se llama (Evitar llamadas recurentes)
        if (!hasValue) this.$store.dispatch('fetchService', {name: 'getStatusShipped'})
        //Si ya hay datos en el store pero se borro el value porq se cerro el modal se llena con su valor (Esto se hace en el watch pero solo se activa una vez)
        if (hasValue && this.integration.status_shipped) this.formIntegration.status_shipped = this.deliveryStatuses[this.getIndex(this.integration.status_shipped, this.deliveryStatuses, 'id')]
        this.fieldsIntegration.push({ fieldType: 'FieldSelect', useLabel: true, name: 'status_shipped', label: 'Nuevo estado', placeholder: 'Seleccione un estado para shipped', containerClass: 'col-12 col-md-6 container-info', options: this.deliveryStatuses })
      }
      if (id === 10) {
        if (!this.formCustomIntegration?.hash) {
          const randomString = (length, chars) => {
            let result = ''
            for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
            return result
          }
          this.formCustomIntegration.hash = randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')
        }
      }
      this.keyFormRenderCustomIntegration++
      this.keyFormRenderIntegration++
    },
    getWarehouse(value) {
      const params = { company_id: this.$route.params?.id }
      const queryParams = { str: value }
      return this.baseService.callService('getWarehouseCompanies', queryParams, params)
        .then(resp => { return resp.data.warehouses.map(el => ({...el, text: el.name})) })
        .catch(err => { return err })
    },
    validitionToShow(custom) {
      if (!Object.prototype.hasOwnProperty.call(custom, 'marketplace')) return true
      return custom.marketplace && this.listIntegrationsCompany.company_data?.marketplace
    },
    showConfigInput(show) {
      if (this.textareaConfigMkc) this.textareaConfigMkc = null
      this.configMarketplace.viewInput = show
    },
    addConfigIntegration() {
      if (!this.setCustomDataTable()) return
      this.loading.configIntg = true
      //cambiamos solo el value del objeto customization ya que tiene otros valores por defecto q se deben mantener
      const newCustomaizationVal = this.integration.customization.map(intg => {
        const n_intg = Object.keys(this.formCustomIntegration).find(key => intg.field === key)
        if (n_intg) {
          return {
            ...intg,
            value: ['select'].includes(intg?.type) ? this.formCustomIntegration[n_intg]?.id || null : ['checkbox'].includes(intg?.type) ? !!this.formCustomIntegration[n_intg] : this.formCustomIntegration[n_intg]
          }
        }
      })
      const queryParams = {
        ...this.integration,
        sellers: JSON.stringify(this.textareaConfigMkc ? this.newSellersAdd : this.integration.sellers) || '[]',
        customization: newCustomaizationVal
      }
      if (this.formIntegration) {
        Object.keys(this.formIntegration).forEach(key => {
          if (['bilateral', 'warehouse_id', 'status_shipped'].includes(key)) queryParams[key] = this.formIntegration[key]?.id || null 
          else if (key === 'decimal') {
            queryParams[key] = this.formIntegration[key]
          } else if (['custom_mapping', 'rule_tags'].includes(key)) {
            queryParams[key] =  JSON.parse(this.formIntegration[key])
          } else {
            //statuses
            queryParams[key] =  this.formIntegration[key]
          }
        })
      }
      const params = {
        company_id: this.$route.params?.id
      }
      this.$store.dispatch('fetchService', { name: 'updateIntegrationsCompany', queryParams, params, hideAlert: true, 
        onSuccess: () => {
          this.loading.configIntg = false
          this.redirectIntegration(queryParams)
          this.close(true)
        }, 
        onError: (err) => {
          this.loading.configIntg = false
          this.$alert(this.$t('msg-problema-configurar-integracion', {code: err})) 
        }
      })
    },
    setCustomDataTable() {
      //Ejem input correcto: {"ade":{"company_id":4968,"warehouse_code":"LES"}}
      try {
        if (!this.textareaConfigMkc) return true 
        const sellers = this.integration.sellers || []
        const newSellers = JSON.parse(this.textareaConfigMkc)
        this.newSellersAdd = Object.assign({}, sellers, newSellers)
        return true
      } catch (error) {
        this.$bvToast.toast('Formato de configuración marketplace incorrecto', {
          title: '¡Oops!',
          variant: 'danger',
          solid: true
        })
        return false
      }
    },
    getIndex(val, array, key) {
      return array.findIndex((el) => el[key] === val)
    },
    cleanFormAndFields() {
      this.fieldsCustomIntegration = []
      this.fieldsIntegration = []
      this.formCustomIntegration = {}
      this.formIntegration = {}
      if (this.textareaConfigMkc) this.textareaConfigMkc = null   
    },
    close(fromConfigInt) {
      this.$bvModal.hide('ModalCustomIntegration')
      if (fromConfigInt) {
        this.$success(this.$t('msg-exito-configurar-integracion'))
        this.$emit('refresh', true)
      }
    },
    redirectIntegration(integration) {
      const { customization } = integration
      const app_id = customization?.find((item) => item?.field === 'app_id')?.value
      if (integration.integration_id === 11 && app_id) {
        const urlRedirect = `https://www.tiendanube.com/apps/${app_id}/authorize`
        window.open(urlRedirect, '_blank')
      }
    }
  }

}
</script>

<style lang="scss">
</style>
