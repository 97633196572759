<template>
  <div class="p-1 bg-white">
    <div class="mx-2">
      <table-render :key="keyTableIntegrationsRender" id="tableIntegrations" v-if="show.tableIntegrations" :rows.sync="rowsTableIntegrations" :schema="schemaTableIntegrations" striped>
        <template #logo="scope">
          <img-loader :src="scope.rowdata.ruteImg" :refId="scope.rowdata.name" :alt="`Logo ${scope.rowdata.name}`" classImg="logo-adapter"/>
        </template>
        <template #status="scope">
          <div class="d-flex align-items-center">
            <b-form-checkbox :checked="scope.rowdata.webhook_enable_order && scope.rowdata.status === 1" @change="changeStatusIntegration(scope.rowdata)" :ref="`switch-${scope.rowdata.name}`" switch>
              <feather-icon icon="InfoIcon" v-b-tooltip.hover.top="'Antes de cambiar el estado se debe configurar la integración'"/>
            </b-form-checkbox>
            <b-spinner :ref="`loading-${scope.rowdata.name}`" class="d-none" :variant="scope.rowdata.webhook_enable_order && scope.rowdata.status === 1 ? null : 'primary'"></b-spinner>
          </div>
        </template>
        <template #actionBtn="scope">
          <b-button size="sm" @click="() => openModal('ModalConfigIntegration', scope.rowdata)" variant="primary">Configurar</b-button>
        </template>
      </table-render>
      <div class="table-render-skeleton" v-show="!show.tableIntegrations">
        <b-skeleton-table :rows="10" :columns="4"/>
      </div>
    </div>
    <modal-config-integration :integration="selectIntegration" :warehouses="warehousesParsed" @refresh="getListIntegrations"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalConfigIntegration from './ModalConfigIntegration.vue'

export default {
  name: 'tab-markeplace-company',
  components: { ModalConfigIntegration },
  data () {
    return {
      show: {
        tableMKP: false,
        tableIntegrations: false,
        cencoIntegrations: false
      },
      disable: {},
      keyTableIntegrationsRender: 0,
      rowsTableIntegrations: [],
      schemaTableIntegrations: [],
      selectIntegration: null,
      warehousesParsed: null
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      listIntegrationsCompany: 'getListIntegrationsCompany',
      warehouseCompanies: 'getWarehouseCompanies'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) if (!['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) this.show.newWarehouseBtn = true
    },
    listIntegrationsCompany() {
      this.cencoIntegrations = this.hasCencoIntegration()
      this.rowsTableIntegrations = this.listIntegrationsCompany.rows.map(intg => {
        this.disable[intg.name] = false
        return {
          ...intg,
          ruteImg: `https://storage.googleapis.com/enviame-logos${intg.logo}`,
          webhook_enable_order: (typeof intg.webhook_enable_order === 'boolean') ? intg.webhook_enable_order : true
        }
      })
      this.show.tableIntegrations = true
    },
    warehouseCompanies() {
      this.warehousesParsed = this.warehouseCompanies.rows.map(wh => (
        {
          ...wh,
          text: wh.name
        }
      ))
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      // Obtiene listado de integraciones
      if (!!this.listIntegrationsCompany) this.getListIntegrations()

      if (!['admin', 'superadmin'].includes(this.$session.get('cas_user')?.role)) this.show.newWarehouseBtn = true
      this.schemaTableIntegrations = [
        { label: 'Integración', key: 'logo', useSlot: true, class: 'w-25 img-container'},
        { label: 'Tipo', key: 'type' },
        { label: 'Estado', key: 'status', useSlot: true },
        { label: 'Acción', key: 'actionBtn', useSlot: true }
      ]
      if (!!this.warehouseCompanies) this.$store.dispatch('fetchService', { name: 'getWarehouseCompanies', params: {company_id: this.$route.params?.id}})
    },
    getListIntegrations(formConfig = false) {
      this.$store.dispatch('fetchService', { name: 'getListIntegrationsCompany', params: {company_id: this.$route.params?.id}})
    },
    /**
    * Indica si esta empresa tiene integración con Cenco.
    * @return bool
    */
    hasCencoIntegration() {
      const integrations = this.listIntegrationsCompany.company_data?.customization_integrations
      return !!(integrations && integrations?.shipedge) // && integrations?.shipedge?.warehouse
    },
    changeStatusIntegration(integrationData) {
      // Loading effect
      this.$refs[`loading-${integrationData.name}`].classList.remove('d-none')
      this.$refs[`switch-${integrationData.name}`].$el.classList.add('d-none')

      const params = {
        // company_id: this.companyData.id
        company_id: this.listIntegrationsCompany.meta.params?.company_id
      }
      const queryParams = {
        active: !integrationData.webhook_enable_order,
        id: integrationData.id
      }
      this.$store.dispatch('fetchService', { name: 'changeIntegrationStatusCompany', queryParams, params,
        onSuccess: () => {
          this.$success(this.$t('msg-exito-change-webhok-status'))
          //Se llama el companyData porq ahi es q esta el datos del webhook activation
          this.getListIntegrations()
          this.$refs[`switch-${integrationData.name}`].$el.classList.remove('d-none')
          this.$refs[`loading-${integrationData.name}`].classList.add('d-none')
        },
        onError: (err) => this.keyTableIntegrationsRender++
      })
    },
    openModal (name, data) {
      this.selectIntegration = data
      this.$bvModal.show(name)
    }
  }
}
</script>

<style lang="scss">
.logo-adapter{
  aspect-ratio: 20/9;
  object-fit: contain;
}
.table.b-table > * > tr > *:first-child.img-container{
  padding: 0 2rem !important;  
}
</style>
